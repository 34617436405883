<template>
  <div class="container page-404">
    <div class="title-404">
      <span class="blink-animate">4</span
      ><BaseSpinner
        speed="slow"
        brand /><span>4</span>
    </div>
    <h1 class="text-bold text-size-h1 mb-l">Страница не найдена</h1>
    <div v-if="user">
      <BaseButton
        v-if="isMentor"
        tag="router-link"
        :to="{ name: 'mentor-index' }"
        >В кабинет наставника</BaseButton
      >
      <BaseButton
        v-else
        tag="router-link"
        :to="{ name: 'user-index' }"
        >В кабинет участника</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PageNotFound",
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
    }),
  },
  metaInfo() {
    return {
      title: "Страница не найдена",
    };
  },
};
</script>

<style lang="less" scoped>
.page-404 {
  text-align: center;
}

.title-404 {
  font-size: 10em;
  line-height: 1;

  &::v-deep .base-spinner {
    width: 0.73em !important;
    height: 0.73em !important;
  }

  &::v-deep .base-spinner__rotator {
    animation-duration: 20s;
  }
}
</style>
